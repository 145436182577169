<template v-slot:top>
    <v-container>
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col md="4"> Purchase Orders</v-col>
                    <v-col offset-md="6" md="2">
                        <v-spacer></v-spacer>
                        <v-text-field v-model="search"
                                      append-icon="mdi-magnify"
                                      label="Search"
                                      single-line
                                      hide-details
                                      clearable
                                      @click:clear="searchCleared">
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-row class="mb-6">
                <v-btn color="secondary"
                       dark
                       class="ml-5"
                       @click="showDialogAddPurchaseOrder">
                    Add
                </v-btn>
                <v-btn color="info"
                       class="ml-2"
                       @click="showDialogImportPurchaseOrders">
                    Import Excel File
                </v-btn>
                <v-btn color="default"
                       class="ml-2"
                       @click="showDialogHistory">
                    View Upload History
                </v-btn>
            </v-row>
            <!--Edit Purchase Order Dialog-->
            <v-row>
                <v-dialog v-model="dialogEditPurchaseOrderView" v-if="dialogEditPurchaseOrderView"
                          @click:outside="closeEditPurchaseOrdersDialog"
                          max-width="850px">
                    <v-toolbar dark color="primary">
                        <v-btn icon dark @click="closeEditPurchaseOrdersDialog">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>
                            Edit Purchase Order Details
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn dark text @click="closeEditPurchaseOrdersDialog">Close</v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                    <v-card>
                        <v-card-text>
                            <v-container>
                                <form>
                                    <v-container>
                                        <v-row>
                                            <v-col cols="12">
                                                <div class="text-h6">
                                                    <strong>Patient:</strong> {{ patientDialogValues.patientFirstName }}
                                                    {{ patientDialogValues.patientLastName }}
                                                </div>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="6">
                                                <div class="text-h6"><strong>PO ID:</strong> {{ editPurchaseOrderValues.legacyId }}</div>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-menu ref="poDateMenu"
                                                        v-model="poDateMenu"
                                                        :close-on-content-click="false"
                                                        :return-value.sync="editPurchaseOrderValues.poDate"
                                                        transition="scale-transition"
                                                        offset-y
                                                        min-width="auto">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field v-model="editPoDateFormatted"
                                                                      label="Purchase Order Date"
                                                                      prepend-icon="mdi-calendar"
                                                                      v-bind="attrs"
                                                                      v-on="on">
                                                        </v-text-field>
                                                    </template>
                                                    <v-date-picker v-model="editPurchaseOrderValues.poDate"
                                                                   no-title
                                                                   scrollable
                                                                   @input="$refs.poDateMenu.save(editPurchaseOrderValues.poDate)">
                                                    </v-date-picker>
                                                </v-menu>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="6">
                                                <v-btn color="secondary" @click="showProviderDialog"
                                                       v-if="editPurchaseOrderValues.providerId == null">
                                                    Add Provider
                                                </v-btn>
                                                <v-btn color="secondary" @click="showProviderDialog" v-else>Change Provider</v-btn>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-text-field v-model="provider.name"
                                                              label="Provider Name"
                                                              readonly>
                                                </v-text-field>
                                            </v-col>

                                        </v-row>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-textarea v-model="editPurchaseOrderValues.note"
                                                            outlined
                                                            label="Notes">
                                                </v-textarea>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-textarea v-model="editPurchaseOrderValues.assignedNote"
                                                            outlined
                                                            label="Assignment Note">
                                                </v-textarea>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="4">
                                                <v-select v-model="editPurchaseOrderValues.isShipmentRequested"
                                                          label="Is Shipment Requested"
                                                          :items="booleanItems"
                                                          outlined
                                                          required>
                                                </v-select>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-select v-model="editPurchaseOrderValues.onFulfillmentHold"
                                                          label="On Fulfillment Hold"
                                                          :items="booleanItems"
                                                          outlined>
                                                </v-select>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-select v-model="editPurchaseOrderValues.isDLC"
                                                          label="Is DLC"
                                                          :items="booleanItems"
                                                          outlined>
                                                </v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="6">
                                                <v-select v-model="editPurchaseOrderValues.initialDeliveryMethod"
                                                          label="Initial Delivery Method"
                                                          :items="deliveryMethodItems"
                                                          outlined>
                                                </v-select>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-select v-model="editPurchaseOrderValues.trainingTypeDescription"
                                                          label="Training Type Description"
                                                          :items="trainingTypeItems"
                                                          outlined>
                                                </v-select>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                    <v-row>
                                        <v-card-actions>
                                            <v-btn color="primary"
                                                   dark
                                                   @click="saveEdit">
                                                Save Edit
                                            </v-btn>
                                            <v-btn color="primary"
                                                   dark
                                                   text
                                                   @click="closeEditPurchaseOrdersDialog">
                                                Close
                                            </v-btn>
                                        </v-card-actions>
                                    </v-row>
                                </form>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-row>
            <!--Add Dialog-->
            <v-row>
                <v-dialog v-model="dialogAddPurchaseOrder" v-if="dialogAddPurchaseOrder"
                          max-width="850px"
                          @click:outside="closeAddPurchaseOrdersDialog">
                    <v-toolbar dark color="primary">
                        <v-btn icon dark @click="closeAddPurchaseOrdersDialog">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>
                            Add Purchase Order
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn dark text @click="closeAddPurchaseOrdersDialog">Close</v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                    <v-card>
                        <v-card-text>
                            <v-container>
                                <form>
                                    <v-row>
                                        <v-col cols="6">
                                            <v-radio-group v-model="isRenewalPatient"
                                                           row
                                                           mandatory>
                                                <v-radio label="New Patient"
                                                         v-on:click="clearAddPurchaseOrderForm"
                                                         :checked="isRenewalPatient"
                                                         v-bind:value="false"></v-radio>
                                                <v-radio default label="Renewal"
                                                         :checked="isRenewalPatient"
                                                         v-bind:value="true"
                                                         v-if="customerId == 1"></v-radio>
                                            </v-radio-group>
                                        </v-col>
                                        <v-col cols="6" v-if="!isRenewalPatient">
                                            <v-checkbox @change="demoCheckboxUpdated"
                                                        v-model="demoCheckbox"
                                                        label="Demo"
                                                        class="float-right">
                                            </v-checkbox>
                                        </v-col>
                                    </v-row>
                                    <!--Renewal Patient-->
                                    <v-container v-if="isRenewalPatient">
                                        <v-row>
                                            <v-col cols="12">
                                                <ordersPatientSearch v-on:patient-selected="searchPurchaseOrder" />
                                            </v-col>
                                        </v-row>
                                        <v-container v-show="showNoPatientRecord">
                                            <div class="text-h6">There is no existing purchase order for this patient.</div>
                                        </v-container>
                                        <v-container v-show="showTooSoonToRenew">
                                            <div class="text-h6">It is too soon to renew this patient.</div>
                                        </v-container>
                                        <v-container v-show="showRenewPurchaseOrder">
                                            <v-row>
                                                <v-col cols="12">
                                                    <div class="text-h6">
                                                        <strong>Patient:</strong> {{ purchaseOrderAdd.patientFirstName }}
                                                        {{ purchaseOrderAdd.patientLastName }}
                                                    </div>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="6">
                                                    <v-text-field v-model="purchaseOrderAdd.fileStoreObjectName"
                                                                  prepend-icon="mdi-image"
                                                                  label="Purchase Order contract Google Drive URL link">
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="6">
                                                    <v-text-field v-model="purchaseOrderAdd.legacyNumber"
                                                                  required
                                                                  label="PO Number">
                                                    </v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="6">
                                                    <v-text-field v-model="purchaseOrderAdd.contractDeviceCount"
                                                                  required
                                                                  label="Number of Devices for attached Purchase Order">
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="6">
                                                    <v-text-field v-model="purchaseOrderAdd.serialNumber"
                                                                  v-mask=maskSerialNumber
                                                                  :counter="15"
                                                                  :maxlength="15"
                                                                  :rules="$data.serialNumberRules"
                                                                  label="Device Serial Number"
                                                                  required>
                                                    </v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="6">
                                                    <v-btn color="secondary" @click="showSiteDialog" v-if="purchaseOrderAdd.siteId == null">
                                                        Add
                                                        Site
                                                    </v-btn>
                                                    <v-btn color="secondary" @click="showSiteDialog" v-else>Change Site</v-btn>
                                                </v-col>
                                                <v-col cols="6">
                                                    <v-text-field v-model="site.name"
                                                                  label="Site Name"
                                                                  readonly>
                                                    </v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12">
                                                    <v-textarea v-model="purchaseOrderAdd.note"
                                                                outlined
                                                                label="Notes">
                                                    </v-textarea>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12">
                                                    <v-textarea v-model="purchaseOrderAdd.shippingNote"
                                                                outlined
                                                                label="Shipping Notes">
                                                    </v-textarea>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="6">
                                                    <v-menu ref="enrollmentDateMenu"
                                                            v-model="enrollmentDateMenu"
                                                            :close-on-content-click="false"
                                                            :return-value.sync="purchaseOrderAdd.enrollmentDate"
                                                            transition="scale-transition"
                                                            offset-y
                                                            min-width="auto">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field v-model="enrollmentDateFormatted"
                                                                          label="Enrollment Date"
                                                                          prepend-icon="mdi-calendar"
                                                                          v-bind="attrs"
                                                                          v-on="on">
                                                            </v-text-field>
                                                        </template>
                                                        <v-date-picker v-model="purchaseOrderAdd.enrollmentDate"
                                                                       no-title
                                                                       scrollable
                                                                       @input="$refs.enrollmentDateMenu.save(purchaseOrderAdd.enrollmentDate)">
                                                        </v-date-picker>
                                                    </v-menu>
                                                </v-col>
                                                <v-col cols="6">
                                                    <v-menu v-model="assignedDateMenu"
                                                            ref="assignedDateMenu"
                                                            :close-on-content-click="false"
                                                            :return-value.sync="purchaseOrderAdd.assigned"
                                                            transition="scale-transition"
                                                            offset-y
                                                            min-width="auto">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field v-model="assignmentDateFormatted"
                                                                          label="Assigned Date"
                                                                          prepend-icon="mdi-calendar"
                                                                          v-bind="attrs"
                                                                          v-on="on">
                                                            </v-text-field>
                                                        </template>
                                                        <v-date-picker v-model="purchaseOrderAdd.assigned"
                                                                       no-title
                                                                       scrollable
                                                                       @input="$refs.assignedDateMenu.save(purchaseOrderAdd.assigned)">
                                                        </v-date-picker>
                                                    </v-menu>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="4">
                                                    <v-menu v-model="shippingDateMenu"
                                                            ref="shippingDateMenu"
                                                            :close-on-content-click="false"
                                                            :return-value.sync="purchaseOrderAdd.shipped"
                                                            transition="scale-transition"
                                                            offset-y
                                                            min-width="auto">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field v-model="shippingDateFormatted"
                                                                          label="Shipping Date"
                                                                          prepend-icon="mdi-calendar"
                                                                          v-bind="attrs"
                                                                          v-on="on">
                                                            </v-text-field>
                                                        </template>
                                                        <v-date-picker v-model="purchaseOrderAdd.shipped"
                                                                       no-title
                                                                       scrollable
                                                                       @input="$refs.shippingDateMenu.save(purchaseOrderAdd.shipped)">
                                                        </v-date-picker>
                                                    </v-menu>
                                                </v-col>
                                                <v-col cols="4">
                                                    <v-menu v-model="expectedDateMenu"
                                                            ref="expectedDateMenu"
                                                            :close-on-content-click="false"
                                                            :return-value.sync="purchaseOrderAdd.expectedDelivery"
                                                            transition="scale-transition"
                                                            offset-y
                                                            min-width="auto">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field v-model="expectedDateFormatted"
                                                                          label="Expected Delivery Date"
                                                                          prepend-icon="mdi-calendar"
                                                                          v-bind="attrs"
                                                                          v-on="on">
                                                            </v-text-field>
                                                        </template>
                                                        <v-date-picker v-model="purchaseOrderAdd.expectedDelivery"
                                                                       no-title
                                                                       scrollable
                                                                       @input="$refs.expectedDateMenu.save(purchaseOrderAdd.expectedDelivery)">
                                                        </v-date-picker>
                                                    </v-menu>
                                                </v-col>
                                                <v-col cols="4">
                                                    <v-menu v-model="deliveredDateMenu"
                                                            ref="deliveredDateMenu"
                                                            :close-on-content-click="false"
                                                            :return-value.sync="purchaseOrderAdd.delivered"
                                                            transition="scale-transition"
                                                            offset-y
                                                            min-width="auto">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field v-model="deliveredDateFormatted"
                                                                          label="Delivered Date"
                                                                          prepend-icon="mdi-calendar"
                                                                          v-bind="attrs"
                                                                          v-on="on">
                                                            </v-text-field>
                                                        </template>
                                                        <v-date-picker v-model="purchaseOrderAdd.delivered"
                                                                       no-title
                                                                       scrollable
                                                                       @input="$refs.deliveredDateMenu.save(purchaseOrderAdd.delivered)">
                                                            <v-spacer></v-spacer>
                                                        </v-date-picker>
                                                    </v-menu>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="6">
                                                    <v-btn color="secondary" @click="showProviderDialog" :disabled="isAddProviderButtonDisabled">
                                                        <span v-if="purchaseOrderAdd.providerId == null">Add Provider</span>
                                                        <span v-else>Change Provider</span>
                                                    </v-btn>
                                                </v-col>
                                                <v-col cols="6">
                                                    <v-text-field v-model="provider.name"
                                                                  label="Provider Name"
                                                                  readonly>
                                                    </v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="6">
                                                    <v-select v-model="purchaseOrderAdd.isDLC"
                                                              label="Is DLC"
                                                              :items="booleanItems"
                                                              outlined>
                                                    </v-select>
                                                </v-col>
                                                <v-col cols="6">
                                                    <v-select v-model="purchaseOrderAdd.onFulfillmentHold"
                                                              label="On Fulfillment Hold"
                                                              :items="booleanItems"
                                                              outlined>
                                                    </v-select>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="6">
                                                    <v-select v-model="purchaseOrderAdd.initialDeliveryMethod"
                                                              label="Initial Delivery Method"
                                                              :items="deliveryMethodItems"
                                                              outlined>
                                                    </v-select>
                                                </v-col>
                                                <v-col cols="6">
                                                    <v-select v-model="purchaseOrderAdd.trainingTypeDescription"
                                                              label="Training Type Description"
                                                              :items="trainingTypeItems"
                                                              outlined>
                                                    </v-select>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-container>
                                    <!--New Patient-->
                                    <v-container v-else>
                                        <v-row>
                                            <v-col cols="6">
                                                <v-text-field v-model="purchaseOrderAdd.patientFirstName"
                                                              label="Patient First Name">
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-text-field v-model="purchaseOrderAdd.patientLastName"
                                                              label="Patient Last Name">
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="4">
                                                <v-text-field v-model="purchaseOrderAdd.patientNickName"
                                                              label="Patient Nick Name">
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-text-field v-model="purchaseOrderAdd.patientMiddleName"
                                                              label="Patient Middle Name">
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-text-field v-model="purchaseOrderAdd.patientICN"
                                                              label="Patient ICN"
                                                              required>
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="6">
                                                <v-text-field v-model="purchaseOrderAdd.last4"
                                                              v-mask="'####'"
                                                              :counter="4"
                                                              label="Last 4 of patient's SSN"
                                                              required>
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="6" v-if="customerId == 1">
                                                <v-text-field v-model="purchaseOrderAdd.serialNumber"
                                                              v-mask=maskSerialNumber
                                                              :counter="15"
                                                              :maxlength="15"
                                                              :rules="$data.serialNumberRules"
                                                              label="Device Serial Number"
                                                              required>
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="6">
                                                <v-text-field v-model="purchaseOrderAdd.fileStoreObjectName"
                                                              prepend-icon="mdi-image"
                                                              label="Presciption contract Google Drive URL link">
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="6">
                                                <v-text-field v-model="purchaseOrderAdd.legacyNumber"
                                                              required
                                                              label="PO Number">
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-text-field v-model="purchaseOrderAdd.contractDeviceCount"
                                                              required
                                                              label="Number of Devices for attached Purchase Order">
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="6">
                                                <v-text-field v-model="purchaseOrderAdd.residencePhone"
                                                              prepend-icon="mdi-phone"
                                                              v-mask="'###-###-####'"
                                                              :rules="$data.phoneNumberValidRules"
                                                              label="Residence Phone Number"
                                                              required>
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-text-field v-model="purchaseOrderAdd.cellPhone"
                                                              prepend-icon="mdi-phone"
                                                              v-mask="'###-###-####'"
                                                              :rules="$data.phoneNumberValidRules"
                                                              label="Cellular Phone Number"
                                                              required>
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row v-for="address in newPatientAddresses"
                                               :key="address.entityAddressId">
                                            <v-col>
                                                <v-row>
                                                    <v-col md="12">
                                                        <v-text-field prepend-icon="mdi-map-marker"
                                                                      ref="autocomplete"
                                                                      label="Search New Address"
                                                                      placeholder=""
                                                                      type="search">
                                                        </v-text-field>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col md="6">
                                                <v-text-field v-model="newPatientAddress.addressLine1"
                                                              label="Address Line 1"
                                                              required
                                                              :rules="$data.addressLine1Rules"
                                                              prepend-icon="mdi-map-marker"></v-text-field>
                                            </v-col>
                                            <v-col md="6">
                                                <v-text-field v-model="newPatientAddress.addressLine2"
                                                              label="Address Line 2"
                                                              prepend-icon="mdi-map-marker"></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col md="6">
                                                <v-text-field v-model="newPatientAddress.city"
                                                              label="City"
                                                              :rules="$data.cityRules"
                                                              required
                                                              prepend-icon="mdi-map-marker"></v-text-field>
                                            </v-col>
                                            <v-col md="6">
                                                <v-select :items="$data.states"
                                                          :rules="$data.stateRules"
                                                          item-text="description"
                                                          item-value="stateId"
                                                          v-model="selectedState"
                                                          @input="handleStateSelection"
                                                          label="State"
                                                          return-object
                                                          required
                                                          prepend-icon="mdi-map-marker">
                                                </v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col md="6">
                                                <v-text-field v-model="newPatientAddress.zipCode"
                                                              label="Zip Code"
                                                              required
                                                              prepend-icon="mdi-map-marker"
                                                              v-mask="'#####'"
                                                              :rules="$data.zipCodeRules"></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="6">
                                                <v-btn color="secondary" @click="showSiteDialog" v-if="purchaseOrderAdd.siteId == null">
                                                    Add
                                                    Site
                                                </v-btn>
                                                <v-btn color="secondary" @click="showSiteDialog" v-else>Change Site</v-btn>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-text-field v-model="site.name"
                                                              label="Site Name"
                                                              readonly>
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="6">
                                                <v-menu ref="enrollmentDateMenu"
                                                        v-model="enrollmentDateMenu"
                                                        :close-on-content-click="false"
                                                        :return-value.sync="purchaseOrderAdd.enrollmentDate"
                                                        transition="scale-transition"
                                                        offset-y
                                                        min-width="auto">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field v-model="enrollmentDateFormatted"
                                                                      label="Enrollment Date"
                                                                      prepend-icon="mdi-calendar"
                                                                      v-bind="attrs"
                                                                      v-on="on">
                                                        </v-text-field>
                                                    </template>
                                                    <v-date-picker v-model="purchaseOrderAdd.enrollmentDate"
                                                                   no-title
                                                                   scrollable
                                                                   @input="$refs.enrollmentDateMenu.save(purchaseOrderAdd.enrollmentDate)">
                                                    </v-date-picker>
                                                </v-menu>
                                            </v-col>
                                            <v-col cols="6" v-if="customerId == 1">
                                                <v-menu v-model="assignedDateMenu"
                                                        ref="assignedDateMenu"
                                                        :close-on-content-click="false"
                                                        :return-value.sync="purchaseOrderAdd.assigned"
                                                        transition="scale-transition"
                                                        offset-y
                                                        min-width="auto">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field v-model="assignmentDateFormatted"
                                                                      label="Assigned Date"
                                                                      prepend-icon="mdi-calendar"
                                                                      v-bind="attrs"
                                                                      v-on="on">
                                                        </v-text-field>
                                                    </template>
                                                    <v-date-picker v-model="purchaseOrderAdd.assigned"
                                                                   no-title
                                                                   scrollable
                                                                   @input="$refs.assignedDateMenu.save(purchaseOrderAdd.assigned)">
                                                    </v-date-picker>
                                                </v-menu>
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="customerId == 1">
                                            <v-col cols="4">
                                                <v-menu v-model="shippingDateMenu"
                                                        ref="shippingDateMenu"
                                                        :close-on-content-click="false"
                                                        :return-value.sync="purchaseOrderAdd.shipped"
                                                        transition="scale-transition"
                                                        offset-y
                                                        min-width="auto">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field v-model="shippingDateFormatted"
                                                                      label="Shipping Date"
                                                                      prepend-icon="mdi-calendar"
                                                                      v-bind="attrs"
                                                                      v-on="on">
                                                        </v-text-field>
                                                    </template>
                                                    <v-date-picker v-model="purchaseOrderAdd.shipped"
                                                                   no-title
                                                                   scrollable
                                                                   @input="$refs.shippingDateMenu.save(purchaseOrderAdd.shipped)">
                                                    </v-date-picker>
                                                </v-menu>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-menu v-model="expectedDateMenu"
                                                        ref="expectedDateMenu"
                                                        :close-on-content-click="false"
                                                        :return-value.sync="purchaseOrderAdd.expectedDelivery"
                                                        transition="scale-transition"
                                                        offset-y
                                                        min-width="auto">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field v-model="expectedDateFormatted"
                                                                      label="Expected Delivery Date"
                                                                      prepend-icon="mdi-calendar"
                                                                      v-bind="attrs"
                                                                      v-on="on">
                                                        </v-text-field>
                                                    </template>
                                                    <v-date-picker v-model="purchaseOrderAdd.expectedDelivery"
                                                                   no-title
                                                                   scrollable
                                                                   @input="$refs.expectedDateMenu.save(purchaseOrderAdd.expectedDelivery)">
                                                    </v-date-picker>
                                                </v-menu>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-menu v-model="deliveredDateMenu"
                                                        ref="deliveredDateMenu"
                                                        :close-on-content-click="false"
                                                        :return-value.sync="purchaseOrderAdd.delivered"
                                                        transition="scale-transition"
                                                        offset-y
                                                        min-width="auto">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field v-model="deliveredDateFormatted"
                                                                      label="Delivered Date"
                                                                      prepend-icon="mdi-calendar"
                                                                      v-bind="attrs"
                                                                      v-on="on">
                                                        </v-text-field>
                                                    </template>
                                                    <v-date-picker v-model="purchaseOrderAdd.delivered"
                                                                   no-title
                                                                   scrollable
                                                                   @input="$refs.deliveredDateMenu.save(purchaseOrderAdd.delivered)">
                                                        <v-spacer></v-spacer>
                                                    </v-date-picker>
                                                </v-menu>
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="customerId == 1">
                                            <v-col cols="6">
                                                <v-text-field v-model="purchaseOrderAdd.dALCReferenceNumber"
                                                              label="DALC Reference Number">
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-text-field v-model="purchaseOrderAdd.trackingNumber"
                                                              label="Tracking Number">
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-textarea v-model="purchaseOrderAdd.note"
                                                            outlined
                                                            label="Notes">
                                                </v-textarea>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-textarea v-model="purchaseOrderAdd.shippingNote"
                                                            outlined
                                                            label="Shipping Notes">
                                                </v-textarea>
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="customerId == 1">
                                            <v-col cols="6">
                                                <v-btn color="secondary" @click="showProviderDialog" :disabled="isAddProviderButtonDisabled">
                                                    <span v-if="purchaseOrderAdd.providerId == null">Add Provider</span>
                                                    <span v-else>Change Provider</span>
                                                </v-btn>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-text-field v-model="provider.name"
                                                              label="Provider Name"
                                                              readonly>
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="6">
                                                <v-select v-model="purchaseOrderAdd.isDLC"
                                                          label="Is DLC"
                                                          :items="booleanItems"
                                                          @change="isDLCChanged"
                                                          outlined>
                                                </v-select>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-select v-model="purchaseOrderAdd.onFulfillmentHold"
                                                          label="On Fulfillment Hold"
                                                          :items="booleanItems"
                                                          outlined>
                                                </v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="6">
                                                <v-select v-model="purchaseOrderAdd.initialDeliveryMethod"
                                                          label="Initial Delivery Method"
                                                          :items="deliveryMethodItems"
                                                          :readonly="purchaseOrderAdd.initialDeliveryMethod == 'DLC'"
                                                          outlined>
                                                </v-select>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-select v-model="purchaseOrderAdd.trainingTypeDescription"
                                                          label="Training Type Description"
                                                          :items="trainingTypeItems"
                                                          outlined>
                                                </v-select>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                    <v-row class="table-danger pa-3" v-if="errors.length > 0">
                                        <ul v-for="error in errors" :key="error">
                                            <li>{{ error }}</li>
                                        </ul>
                                    </v-row>
                                    <v-row>
                                        <v-card-actions>
                                            <v-btn color="primary"
                                                   dark
                                                   :disabled="loading"
                                                   @click="checkPurchaseOrderForm">
                                                Add
                                            </v-btn>
                                            <v-btn color="primary"
                                                   dark
                                                   text
                                                   @click="closeAddPurchaseOrdersDialog">
                                                Close
                                            </v-btn>
                                        </v-card-actions>
                                    </v-row>
                                </form>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-row>
            <!--Void Reason Dialog-->
            <v-row>
                <v-dialog v-model="dialogVoidOrderView" v-if="dialogVoidOrderView"
                          @click:outside="closeVoidOrderDialog"
                          max-width="850px">

                    <v-toolbar dark color="primary">
                        <v-btn icon dark @click="closeVoidOrderDialog">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>
                            Void Purchase Order Details
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn dark text @click="closeVoidOrderDialog">Close</v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                    <v-card>
                        <v-card-text>
                            <form>
                                <v-row>
                                    <VoidReason @saved="saveVoid"
                                                :order-id="selectedVoidOrderValues.orderId"
                                                :customer-id="selectedVoidOrderValues.customerId"
                                                :patient-first-name="selectedVoidOrderPatientValues.patientFirstName"
                                                :patient-last-name="selectedVoidOrderPatientValues.patientLastName"
                                                @closed="closeVoidOrderDialog"></VoidReason>
                                </v-row>
                            </form>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-row>
            <!--Provider Dialog-->
            <v-row>
                <v-dialog v-model="dialogProvider" v-if="dialogProvider"
                          max-width="850px"
                          @click:outside="closeProviderDialog">
                    <v-toolbar dark color="primary">
                        <v-btn icon dark @click="closeProviderDialog">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>
                            Search For or Add Provider
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn dark text @click="closeProviderDialog">Close</v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                    <v-card>
                        <v-card-text>
                            <form>
                                <v-row>
                                    <v-col cols="12">
                                        <providerSearch v-on:provider-selected="searchProviders"
                                                        :customerId="this.customerId" :patientSiteId="this.patientSiteId" />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <OrderProviderAdd mode="Add" @saved="providerSelected" :entity-providers="providers"
                                                      :customer-id="customerId" @closed="closeProviderDialog"></OrderProviderAdd>
                                </v-row>
                            </form>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-row>
            <!--Site Dialog-->
            <v-row>
                <v-dialog v-model="dialogSite" v-if="dialogSite"
                          max-width="850px"
                          @click:outside="closeSiteDialog">
                    <v-toolbar dark color="primary">
                        <v-btn icon dark @click="closeSiteDialog">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>
                            Search For Site
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn dark text @click="closeSiteDialog">Close</v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                    <v-card>
                        <v-card-text>
                            <form>
                                <v-row>
                                    <v-col cols="12">
                                        <siteSearch v-on:site-selected="searchSites" :isReadonly="false"
                                                    :customerId="this.customerId" />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-card-actions>
                                        <v-btn color="primary"
                                               dark
                                               text
                                               @click="closeSiteDialog">
                                            Close
                                        </v-btn>
                                    </v-card-actions>
                                </v-row>
                            </form>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-row>
            <!--Import Dialog-->
            <v-row>
                <v-dialog v-model="dialogImportPurchaseOrders" v-if="dialogImportPurchaseOrders"
                          max-width="850px"
                          @click:outside="closeImportPurchaseOrdersDialog">
                    <v-toolbar dark color="primary">
                        <v-btn icon dark @click="closeImportPurchaseOrdersDialog">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>
                            Import Purchase Orders from Excel
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn dark text @click="closeImportPurchaseOrdersDialog">Close</v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                    <v-card>
                        <v-card-text>
                            <form>
                                <v-container v-show="showFileUpload">
                                    <v-row class="table-danger pa-3" v-if="errors.length > 0">
                                        <ul v-for="error in errors" :key="error">
                                            <li>{{ error }}</li>
                                        </ul>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="6">
                                            <v-file-input v-model="fileUpload"
                                                          accept=".xlsx"
                                                          label="Select Purchase Order Excel file"
                                                          :rules="[validateUploadFile]"
                                                          required>
                                            </v-file-input>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-text-field v-model="purchaseOrderAdd.fileStoreObjectName"
                                                          prepend-icon="mdi-image"
                                                          label="Presciption contract Google Drive URL link">
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="6">
                                            <v-text-field v-model="purchaseOrderAdd.legacyNumber"
                                                          required
                                                          label="PO Number">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-text-field v-model="purchaseOrderAdd.contractDeviceCount"
                                                          required
                                                          label="Number of Devices for attached Purchase Order">
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row align="center" justify="center" v-if="fileUploading">
                                        <LoadingBar />
                                    </v-row>
                                    <v-row>
                                        <v-card-actions>
                                            <v-btn color="primary"
                                                   dark
                                                   :disabled="loading"
                                                   @click="checkPurchaseOrderUploadForm">
                                                Upload
                                            </v-btn>
                                            <v-btn color="primary"
                                                   dark
                                                   text
                                                   @click="closeImportPurchaseOrdersDialog">
                                                Close
                                            </v-btn>
                                        </v-card-actions>
                                    </v-row>
                                </v-container>
                            </form>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-row>
            <!--View History Dialog-->
            <v-row>
                <v-dialog v-model="dialogHistory" v-if="dialogHistory"
                          fullscreen
                          hide-overlay
                          transition="dialog-bottom-transition">
                    <v-toolbar dark color="primary">
                        <v-btn icon dark @click="closeHistoryDialog">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>
                            Purchase Order Upload History <small>({{ this.purchaseOrderUploadsHistory.length }})</small>
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn dark text @click="closeHistoryDialog">Close</v-btn>
                        </v-toolbar-items>
                    </v-toolbar>

                    <v-row class="pt-5">
                        <v-container>
                            <v-card>
                                <v-card-title>
                                    <v-row>
                                        <v-col>
                                            Purchase Order Upload History
                                            <v-icon @click="showDialogHistory">mdi-refresh</v-icon>
                                        </v-col>
                                    </v-row>
                                </v-card-title>
                                <v-data-table :headers="uploadHistoryHeader"
                                              :items="purchaseOrderUploadsHistory"
                                              sort-by="id"
                                              sort-desc
                                              :loading="loading"
                                              disable-pagination
                                              :hide-default-footer="true"
                                              class="elevation-1"
                                              :fixed-header="true">
                                    <template #[`item.view`]="{ item }">
                                        <v-icon v-if="item.status != 4 && item.status != 1"
                                                medium
                                                class="mr-2"
                                                @click="showDialogHistoryResult(item)">
                                            mdi-open-in-new
                                        </v-icon>
                                    </template>
                                    <template #[`item.created`]="{ item }">
                                        <span>{{ formDateTimeFormat(item.created) }}</span>
                                    </template>

                                    <template #[`item.status`]="{ item }">
                                        <span v-if="item.status !== 2">{{ getStatus(item.status, item.progressPercent)   }}</span>
                                        <v-tooltip bottom v-if="item.status === 2">
                                            <template #[`activator`]="{ on }">
                                                <div class="text-danger" v-on="on">Error</div>
                                            </template>
                                            <span style="font-weight: bold;">Error Message:</span> {{item.errorMessage ? item.errorMessage : "N/A"}}
                                        </v-tooltip>
                                    </template>
                                </v-data-table>
                            </v-card>
                        </v-container>
                    </v-row>
                </v-dialog>
            </v-row>
            <!--Upload Results Dialog-->
            <v-row>
                <v-dialog v-model="dialogResultsPurchaseOrders" v-if="dialogResultsPurchaseOrders"
                          fullscreen
                          hide-overlay
                          transition="dialog-bottom-transition">
                    <v-toolbar dark color="primary">
                        <v-btn icon dark @click="closeResultsPurchaseOrdersDialog">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>
                            Results of Purchase Order Upload from Excel
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn dark text @click="closeResultsPurchaseOrdersDialog">Close</v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                    <v-card>
                        <v-card-text>
                            <v-container>
                                <v-row class="pl-3">
                                    <v-col cols="12" class=" pa-1">
                                        <div class="text-h6 table-danger" v-if="contractDeviceQuotaMet">
                                            The number of devices associated
                                            with this PO Number has been met. Please continue with another PO Number.
                                        </div>
                                        <div class="text-h4 table-danger" v-if="errorOnUpload">
                                            There was an error during the upload process.
                                            Even though an error occurred, some or all entries may have succesful, so please check the results
                                            below.
                                        </div>
                                        <div class="text-h6 table-danger" v-if="errorOnUpload">
                                            The error occurred while on row
                                            {{ errorRowNumber }}.
                                        </div>
                                        <div class="text-h6 table-danger" v-if="errorOnUpload">
                                            The error's returned system message was:
                                            {{ errorMessage }}
                                        </div>
                                        <div class="text-h6 text-success">Patients added succesfully: {{ successfulUploadsCount }}</div>
                                        <div class="text-h6 text-danger">
                                            Patients rejected or with warnings: {{
						rejectedUploadsCount
                                            }}
                                        </div>
                                        <div class="text-h6 text-danger">Upload errors: {{ uploadErrorsCount }}</div>
                                        <p v-if="rejectedUploadsCount != 0">
                                            Some of the patients uploaded could not be added. Please review
                                            the warnings and errors below, make the additions or changes required, and try again.
                                        </p>
                                    </v-col>
                                </v-row>
                                <v-tabs content-class="mt-3">
                                    <v-tab active>
                                        Warnings and Rejections
                                    </v-tab>
                                    <v-tab-item>
                                        <v-data-table :headers="uploadRejectionHeader"
                                                      :items="rejectedUploadedOrders"
                                                      :loading="loading"
                                                      disable-pagination
                                                      :hide-default-footer="true"
                                                      class="elevation-1"
                                                      :item-class="getRowClass">
                                            <template #item.uploadRejectection_formatted="{ item }">
                                                {{ item | formatRejectionType }}
                                            </template>
                                        </v-data-table>
                                    </v-tab-item>
                                    <v-tab>
                                        Successful Uploads
                                    </v-tab>
                                    <v-tab-item>
                                        <v-data-table :headers="uploadSuccessHeader"
                                                      :items="successfulUploadedOrders"
                                                      :loading="loading"
                                                      :hide-default-footer="true"
                                                      disable-pagination
                                                      class="elevation-1"
                                                      :item-class="getRowClassSuccess">
                                            <template #item.uploadRenewal_formatted="{ item }">
                                                {{ item.isRenewalPatient == true ? 'Yes' : 'No' }}
                                            </template>
                                            <template #item.shipmentDelayed_formatted="{ item }">
                                                {{ item.shipmentDelayed == true ? 'Yes' : 'No' }}
                                            </template>
                                        </v-data-table>
                                    </v-tab-item>
                                    <v-tab>
                                        Upload Errors
                                    </v-tab>
                                    <v-tab-item>
                                        <v-data-table :headers="uploadErrorsHeader"
                                                      :items="uploadErrorItems"
                                                      :loading="loading"
                                                      :hide-default-footer="true"
                                                      disable-pagination
                                                      class="elevation-1"
                                                      :item-class="getRowClass">
                                        </v-data-table>
                                    </v-tab-item>
                                </v-tabs>
                                <v-row class="pt-3 pl-1">
                                    <v-card-actions>
                                        <v-btn color="primary"
                                               dark
                                               @click="closeResultsPurchaseOrdersDialog">
                                            Close
                                        </v-btn>
                                    </v-card-actions>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-row>
            <v-data-table :headers="headers"
                          :items="purchaseOrders"
                          sort-by="PODate"
                          sort-desc
                          class="elevation-1"
                          :server-items-length="totalPurchaseOrders"
                          :options.sync="options"
                          :loading="loading"
                          :search="search"
                          :footer-props="{'items-per-page-options': rowsPerPage}">
                <template #item.purchase_order_details="{ item }">
                    {{ item.legacyId }}
                    <v-icon medium class="mr-2" @click="viewPurchaseOrder(item)">
                        mdi-open-in-new
                    </v-icon>
                </template>
                <template #item.full_name="{ item }">
                    {{ item.patientFirstName }} {{ item.patientLastName }}
                    <v-icon medium class="mr-2" @click="viewPatient(item)">
                        mdi-open-in-new
                    </v-icon>
                </template>
                <template v-slot:top>
                    <v-dialog v-model="dialogPO" v-if="dialogPO"
                              fullscreen
                              hide-overlay
                              transition="dialog-bottom-transition">
                        <v-card>
                            <v-toolbar dark color="primary">
                                <v-btn icon dark @click="dialogPO = false">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                                <v-toolbar-title>
                                    Purchase Order - {{ selectedPurchaseOrderValues.legacyId }}
                                </v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-toolbar-items>
                                    <v-btn dark text @click="dialogPO = false">
                                        Close
                                    </v-btn>
                                </v-toolbar-items>
                            </v-toolbar>
                            <PurchaseOrderDetails :poId="selectedPurchaseOrderValues.poId"
                                                  :legacyId="selectedPurchaseOrderValues.legacyId"
                                                  :patientFirstName="selectedPurchaseOrderValues.patientFirstName"
                                                  :patientLastName="selectedPurchaseOrderValues.patientLastName"
                                                  :siteId="selectedPurchaseOrderValues.siteId"
                                                  :created="formDateFormat(selectedPurchaseOrderValues.created)"
                                                  :updatedByUserId="selectedPurchaseOrderValues.updatedByUserId"
                                                  :updatedByUserName="selectedPurchaseOrderValues.updatedByUserName"
                                                  :received="formDateFormat(selectedPurchaseOrderValues.received)"
                                                  :poDate="formDateFormat(selectedPurchaseOrderValues.poDate)"
                                                  :delivered="formDateFormat(selectedPurchaseOrderValues.delivered)"
                                                  :assigned="formDateFormat(selectedPurchaseOrderValues.assigned)"
                                                  :legacyNumber="selectedPurchaseOrderValues.legacyNumber"
                                                  :fileStoreObjectName="selectedPurchaseOrderValues.fileStoreObjectName"
                                                  :processed="formDateFormat(selectedPurchaseOrderValues.processed)"
                                                  :voidDate="formDateFormat(selectedPurchaseOrderValues.voidDate)"
                                                  :voidedReasonId="selectedPurchaseOrderValues.voidedReasonId"
                                                  :voidNote="selectedPurchaseOrderValues.voidNote"
                                                  :trained="formDateFormat(selectedPurchaseOrderValues.trained)"
                                                  :isDeleted="selectedPurchaseOrderValues.isDeleted"
                                                  :note="selectedPurchaseOrderValues.note"
                                                  :assignedNote="selectedPurchaseOrderValues.assignedNote"
                                                  :trainedNote="selectedPurchaseOrderValues.trainedNote"
                                                  :isShipmentRequested="selectedPurchaseOrderValues.isShipmentRequested"
                                                  :isRenewalPatient="selectedPurchaseOrderValues.isRenewalPatient"
                                                  :onFulfillmentHold="selectedPurchaseOrderValues.onFulfillmentHold"
                                                  :providerId="selectedPurchaseOrderValues.providerId"
                                                  :isDLC="selectedPurchaseOrderValues.isDLC"
                                                  :initialDeliveryMethod="selectedPurchaseOrderValues.initialDeliveryMethod"
                                                  :trainingTypeDescription="selectedPurchaseOrderValues.trainingTypeDescription" />
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogPatient" v-if="dialogPatient"
                              fullscreen
                              hide-overlay
                              ransition="dialog-bottom-transition"
                              persistent :retain-focus="false">
                        <v-card>
                            <div style="background: #123559" v-if="false">
                                <v-btn icon dark @click="dialogPatient = false">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                                <v-btn class="float-right" dark text @click="dialogPatient = false"> Close</v-btn>
                            </div>
                            <v-toolbar dark color="primary" v-if="false">
                                <v-btn icon dark @click="closePatientDialog">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                                <v-tooltip bottom open-delay="500">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-toolbar-title class="pl-8" @click="copyToClipboard(patientName)" v-on="on" v-bind="attrs">
                                            {{ patientName }}
                                        </v-toolbar-title>
                                    </template>
                                    <span>Click to copy to clipboard</span>
                                </v-tooltip>
                                <v-tooltip bottom open-delay="500">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-toolbar-title class="pl-8 blue-grey--text text--lighten-4"
                                                         @click="copyToClipboard(patientDesignation)"
                                                         v-on="on"
                                                         v-bind="attrs">
                                            {{ patientDesignation }}
                                        </v-toolbar-title>
                                    </template>
                                    <span>Click to copy to clipboard</span>
                                </v-tooltip>
                                <v-toolbar-title class="pl-8 blue-grey--text text--lighten-4">
                                    {{ customerName }}
                                </v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-toolbar-items>
                                    <v-btn dark text @click="closePatientDialog"> Close</v-btn>
                                </v-toolbar-items>
                                <v-snackbar v-model="clipboardSnackbar" :timeout="1100" bottom>
                                    Copied "{{ clipboardText }}" to clipboard

                                    <template v-slot:action="{ attrs }">
                                        <v-btn color="blue" text v-bind="attrs" @click="clipboardSnackbar = false">
                                            Close
                                        </v-btn>
                                    </template>
                                </v-snackbar>
                            </v-toolbar>
                            <PatientDetails :patientId="patientDialogValues.patientId"
                                            :customerId="patientDialogValues.customerId"
                                            :customer-name="patientDialogValues.customerName"
                                            :key="componentKey"
                                            v-on:closed="closePatientDialog" />
                        </v-card>
                    </v-dialog>
                </template>
                <template #[`item.actions`]="{ item }">
                    <v-menu>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                                <v-icon style="z-index: 2" class="mt-1">mdi-dots-vertical</v-icon>
                            </v-btn>
                        </template>
                        <v-list-item-group v-show="item.voidDate === null || item.voidIsDeleted " elevation="2"
                                           style="position: relative; z-index: 3; background-color: white">
                            <v-list-item v-for="(action, index) in actionsVoid" :key="index" @click="actionSelected(item, action)">
                                <v-list-item-title>{{ action }}</v-list-item-title>
                            </v-list-item>
                        </v-list-item-group>
                        <v-list-item-group v-show="item.voidDate !== null && !item.voidIsDeleted" elevation="2"
                                           style="position: relative; z-index: 3; background-color: white">
                            <v-list-item v-for="(action, index) in actionsUnVoid" :key="index" @click="actionSelected(item, action)">
                                <v-list-item-title>{{ action }}</v-list-item-title>
                            </v-list-item>
                        </v-list-item-group>
                    </v-menu>
                </template>
                <template #[`item.patientName`]="{ item }">
                    {{ item.patientFirstName }} {{ item.patientLastName }}
                    <v-icon medium class="mr-2" v-show="item.voidDate === null" @click="viewPatient(item)">
                        mdi-open-in-new
                    </v-icon>
                </template>
                <template #[`item.isRenewalPatient`]="{ item }">
                    {{ item.isRenewalPatient == true ? 'Yes' : 'No' }}
                </template>
                <template #[`item.poDate`]="{ item }">
                    {{ item.poDate | formatDate }}
                </template>
                <template #[`item.voidedDate`]="{ item }">
                    <div v-show="!item.voidIsDeleted">
                        {{ item.voidDate | formatDate }}
                    </div>
                </template>
            </v-data-table>
        </v-card>
        <v-snackbar v-model="submitSnackbar" :timeout="12000" bottom>
            {{ submitSnackbarText }}
            <v-btn color="blue"
                   align="right"
                   justify="end"
                   class="float-right"
                   @click="submitSnackbar = false">
                Close
            </v-btn>
        </v-snackbar>
    </v-container>
</template>

<script>
    import PatientDetails from "@/components/PatientDetails.vue";
    import ProviderSearch from "@/components/ProviderSearch.vue";
    import LoadingBar from "@/components/Shared/LoadingBar.vue";
    import SiteSearch from "@/components/SiteSearch.vue";
    import { contactDetailsUtils } from "@/mixins/contactDetailsUtils";
    import CustomerPurchaseOrdersService from "@/services/CustomerPurchaseOrdersService";
    import OrderIntakeService from "@/services/OrderIntakeService";
    import PatientDeviceService from "@/services/PatientDeviceService";
    import ProviderService from "@/services/ProviderService";
    import PurchaseOrderService from "@/services/PurchaseOrderService";
    import moment from 'moment';
    import Vue from "vue";
    import OrderProviderAdd from "./OrderProviderAdd.vue";
    import OrdersPatientSearch from "./OrdersPatientSearch.vue";
    import PurchaseOrderDetails from "./PurchaseOrderDetails.vue";
    import VoidReason from "./VoidReason.vue";
    
    Vue.filter('formatDate', function (value) {
        if (value) {
            return moment(String(value)).format('MM/DD/YYYY')
        }
    });

    Vue.filter('unVoidCheck', function (value, action) {
        if (value) {
            console.log('Value check: ' + value);
            console.log('Action check: ' + action);
        }
    });

    Vue.filter('formatRejectionType', function (value) {
        if (value.tooSoonToRenew) {
            return "Too soon to renew";
        } else if (value.missingPatientICN) {
            return "Missing Patient ICN";
        } else if (value.patientMissingDFN) {
            return "Missing PatientIEN (DFN)";
        } else if (value.missingSerialNumber) {
            return "Missing DS#";
        } else if (value.serialNumberAlreadyInUse) {
            return "Duplicate DS#";
        } else if (value.incompleteLastFourOfSocial) {
            return "Incomplete Last Four";
        } else if (value.missingPhoneNumbers) {
            return "Missing Phone Number";
        } else if (value.missingNameParts) {
            return "Missing Full Name";
        } else if (value.renewalSiteChanged) {
            return "Renewal site does match current site";
        } else if (value.renewalProviderChanged) {
            return "Prescribing provider does not match current provider";
        } else if (value.missingEnrollmentDate) {
            return "Missing Enrollment Date";
        } else if (value.missingUpdatedOrderDate) {
            return "Missing Updated Order Date";
        } else if (value.missingStationOrSiteInfo) {
            return "Missing Site";
        } else if (value.addressErrorNotFound) {
            return "Address Not Found";
        } else if (value.addressErrorPartialMatch) {
            return "Address Was Partial Match";
        } else if (value.noMatchingStation) {
            return "No Matching Station";
        } else if (value.missingProviderInfo) {
            return "Missing Provider";
        } else if (value.providerNotInDatabase) {
            return "Provider Not Found";
        } else if (value.serialNumberBadFormat) {
            return "Bad Serial Number Format";
        } else if (value.phoneNumberTooLong) {
            return "Phone number is too long";
        } else if (value.patientIsWithdrawn) {
            return "Patient is withdrawn";
        } else if (value.icnAndPatientNameDontMatch) {
            return "ICN and patient name mismatch";
        } else if (value.missingLocationIEN) {
            return "Missing LocationIEN";
        }
    });

    export default Vue.extend({
        name: "PurchaseOrdersList",
        mixins: [contactDetailsUtils],
        components: {
            PurchaseOrderDetails,
            PatientDetails,
            OrdersPatientSearch,
            OrderProviderAdd,
            SiteSearch,
            LoadingBar,
            ProviderSearch,
            VoidReason
        },

        data: () => ({
            loading: true,
            dialogVoidOrderView: false,
            selectedVoidOrderValues: {},
            selectedVoidOrderPatientValues: {},
            totalPurchaseOrders: 0,
            rejectedUploadsCount: 0,
            successfulUploadsCount: 0,
            uploadErrorsCount: 0,
            contractDeviceQuotaMet: false,
            componentKey: 0,
            selectedState: null,
            dialog: false,
            dialogPO: false,
            dialogImportPurchaseOrders: false,
            dialogAddPurchaseOrder: false,
            dialogEditPurchaseOrderView: false,
            dialogResultsPurchaseOrders: false,
            dialogHistory: false,
            assignedDateMenu: false,
            shippingDateMenu: false,
            expectedDateMenu: false,
            deliveredDateMenu: false,
            dialogProvider: false,
            dialogSite: false,
            enrollmentDateMenu: false,
            poDateMenu: false,
            receivedDateMenu: false,
            assignedMenu: false,
            deliveredMenu: false,
            trainedMenu: false,
            dialogPatient: false,
            showNoPatientRecord: false,
            showEditPurchaseOrder: false,
            showRenewPurchaseOrder: false,
            showTooSoonToRenew: false,
            showFileUpload: true,
            fileUploading: false,
            isRenewalPatient: false,
            submitSnackbar: false,
            submitSnackbarText: "",
            isUploadMode: false,
            demoCheckbox: false,
            options: {},
            providerTypeItems: [],
            providerModeOfContactItems: [],
            uploadFileType: ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"],
            fileUpload: null,
            purchaseOrderSelected: false,
            search: "",
            actionsVoid: ["Edit", "Delete", "Void"],
            actionsUnVoid: ["Delete", "UnVoid"],
            customerId: 1,
            provider: {},
            site: {},
            autocomplete: {},
            errors: [],
            providers: [],
            patientSiteId: 0,
            headers: [
                {
                    text: "PO date",
                    align: "start",
                    value: "poDate",
                },
                { text: "PO ID", value: "purchase_order_details" },
                { text: "Customer", value: "customerName" },
                {
                    text: "Patient Name",
                    align: "start",
                    value: "full_name",
                },
                { text: "Renewal Patient", value: "isRenewalPatient" },
                { text: "Voided Date", value: "voidedDate", sortable: false },
                { text: "Actions", value: "actions", sortable: false },
            ],
            uploadRejectionHeader: [
                { text: "Excel Row Number", value: "rowNumber" },
                { text: "Patient ICN", value: "patientICN" },
                { text: "Error/Warning Type", value: "uploadRejectection_formatted", sortable: false, },
                { text: "Message", value: "rejectionMessage" },
            ],
            uploadSuccessHeader: [
                { text: "Excel Row Number", value: "rowNumber" },
                { text: "Patient ICN", value: "patientICN" },
                { text: "Is Renewal", value: "uploadRenewal_formatted", sortable: true, },
                { text: "Shipment Delayed", value: "shipmentDelayed_formatted", sortable: true, },
            ],
            uploadErrorsHeader: [
                { text: "Excel Row Number", value: "rowNumber" },
                { text: "Patient ICN", value: "patientICN" },
                { text: "Error Message", value: "errorMessage", sortable: true, },
                { text: "Stack Trace", value: "stackTrace" },
            ],
            uploadHistoryHeader: [
                { text: "Id", value: "purchaseOrderUploadId", sortable: true },
                {
                    text: "Prescription Contract Link",
                    value: "fileStoreObjectName",
                    sortable: true,
                },
                { text: "Legacy Number", value: "legacyNumber", sortable: true },
                {
                    text: "Number Of Devices",
                    value: "contractDeviceCount",
                    sortable: true,
                },
                { text: "Status", value: "status", sortable: true },
                { text: "File name", value: "fileName", sortable: true },
                { text: "Uploaded By", value: "updatedByUser", sortable: true },
                { text: "Upload Date", value: "created", sortable: true },
                { text: "View", value: "view", sortable: false },
            ],
            purchaseOrders: [],
            selectedPurchaseOrders: [],
            editedIndex: -1,
            editedItem: {},
            rejectedUploadedOrders: [],
            successfulUploadedOrders: [],
            uploadErrorItems: [],
            errorRowNumber: null,
            errorOnUpload: false,
            errorMessage: null,
            booleanItems: [
                { text: 'True', value: true },
                { text: 'False', value: false },
            ],
            trainingTypeItems: [
                { text: 'Telephonic', value: 'Telephonic' },
                { text: 'Renewal', value: 'Renewal' },
            ],
            uploadOrderValues: {
                patientICN: null,
                patientName: null,
                last4: null,
                residencePhone: null,
                cellPhone: null,
                address: null,
                enrollmentDate: null,
                station: null,
                stationName: null,
                enrollingProvider: null,
                orderDate: null,
                serialNumber: null,
                dALCReferenceNumber: null,
                trackingNumber: null,
                validationStatus: null,
            },
            newPatientAddresses: [
                {
                    entityAddressId: null,
                    addressLine1: "",
                    addressLine2: "",
                    city: "",
                    state: "",
                    stateId: null,
                    zipCode: "",
                    country: "",
                }
            ],
            purchaseOrderAdd: {
                isDLC: true,
                onFulfillmentHold: false,
                initialDeliveryMethod: "DLC",
                trainingTypeDescription: "Telephonic",
                serialNumber: ""
            },
            newPatientAddress: {
                addressLine1: "",
                addressLine2: "",
                city: "",
                state: "",
                stateId: null,
                zipCode: "",
                country: "",
                entityTypeId: 1,
            },
            defaultItem: {
                podate: null,
                poid: null,
                customer: null,
                patientName: null,
                renewal: null,
            },
            patientDialogValues: {
                patientFirstName: String,
                patientLastName: String,
                customerId: Number,
                legacyUniqueId: String,
                firstName: String,
                lastName: String,
                customerName: String,
                patientId: Number,
                patientDesignation: String,
            },
            selectedPurchaseOrderValues: {},
            editPurchaseOrderValues: {},
            purchaseOrderUploadsHistory: [],
            processingCount: 0,
            //testLoppCall: 0
        }),

        computed: {
            deliveryMethodItems() {
                if (this.editPurchaseOrderValues.isDLC === false) {
                    return [
                        { text: 'UPS', value: 'UPS' },
                    ];
                }
                else if (this.purchaseOrderAdd.isDLC === true) {
                    return [
                        { text: 'DLC', value: 'DLC' }
                    ];
                }
                else {
                    return [
                        { text: 'UPS', value: 'UPS' },
                        { text: 'Pre-qualified', value: 'Pre-qualified' },
                        { text: 'Referral Contract', value: 'Referral Contract' },
                        { text: 'In-clinic Distribution', value: 'In-clinic Distribution' }
                    ]
                }
            },
            formTitle() {
                return this.editedIndex === -1 ? "New PO" : "Edit PO";
            },

            rowsPerPage() {
                return this.$rowsPerPage;
            },

            editPoDateFormatted() {
                if (this.editPurchaseOrderValues.poDate == null) {
                    return '';
                }
                let isoDateString = moment(this.editPurchaseOrderValues.poDate).toISOString();
                return new Intl.DateTimeFormat('en-US', {
                    month: '2-digit', day: '2-digit', year: 'numeric'
                }).format(new Date(isoDateString));
            },

            enrollmentDateFormatted() {
                if (this.purchaseOrderAdd.enrollmentDate == null) {
                    return '';
                }
                let isoDateString = moment(this.purchaseOrderAdd.enrollmentDate).toISOString();
                return new Intl.DateTimeFormat('en-US', {
                    month: '2-digit', day: '2-digit', year: 'numeric'
                }).format(new Date(isoDateString));
            },

            assignmentDateFormatted() {
                if (this.purchaseOrderAdd.assigned == null) {
                    return '';
                }
                let isoDateString = moment(this.purchaseOrderAdd.assigned).toISOString();
                return new Intl.DateTimeFormat('en-US', {
                    month: '2-digit', day: '2-digit', year: 'numeric'
                }).format(new Date(isoDateString));
            },

            shippingDateFormatted() {
                if (this.purchaseOrderAdd.shipped == null) {
                    return '';
                }
                let isoDateString = moment(this.purchaseOrderAdd.shipped).toISOString();
                let formatted = new Intl.DateTimeFormat('en-US', {
                    month: '2-digit', day: '2-digit', year: 'numeric'
                }).format(new Date(isoDateString));
                return formatted;
            },

            expectedDateFormatted() {
                if (this.purchaseOrderAdd.expectedDelivery == null) {
                    return '';
                }
                let isoDateString = moment(this.purchaseOrderAdd.expectedDelivery).toISOString();
                let formatted = new Intl.DateTimeFormat('en-US', {
                    month: '2-digit', day: '2-digit', year: 'numeric'
                }).format(new Date(isoDateString));
                return formatted;
            },

            deliveredDateFormatted() {
                if (this.purchaseOrderAdd.delivered == null) {
                    return '';
                }
                let isoDateString = moment(this.purchaseOrderAdd.delivered).toISOString();
                let formatted = new Intl.DateTimeFormat('en-US', {
                    month: '2-digit', day: '2-digit', year: 'numeric'
                }).format(new Date(isoDateString));
                return formatted;
            },

            isAddProviderButtonDisabled() {
                return !this.site.name;
            },
        },

        async mounted() {
            this.initAutocomplete();
        },

        watch: {
            options: {
                handler() {
                    this.getPOs();
                },
                deep: true,
            },
            dialog(val) {
                val || this.close();
            },
            dialogDelete(val) {
                val || this.closeDelete();
            },
            search: {
                handler(val) {
                    if (!val)
                        return;
                    this.fetchEntriesDebounced();
                },
                deep: true,
            }
        },

        methods: {
            getStatus(status, progressPercent) {
                switch (status) {
                    case 1:
                        return "New";
                    case 2:
                        return "Failed" + (progressPercent && progressPercent > 0 ? " - " + progressPercent + "%" : "");
                    case 3:
                        return "Processed";
                    case 4:
                        return "Processing - " + (progressPercent && progressPercent > 0 ? progressPercent + "%" : "");
                    default:
                        return "Unknown Status";
                }
            },
            isDLCChanged(item) {
                if (item == true) {
                    this.purchaseOrderAdd.initialDeliveryMethod = "DLC"
                } else {
                    this.purchaseOrderAdd.initialDeliveryMethod = "UPS"
                }
            },
            getRowClass(item) {
                if (item.addressErrorPartialMatch || item.incompleteLastFourOfSocial || item.providerNotInDatabase || item.renewalProviderChanged || item.renewalSiteChanged || item.missingLocationIEN) {
                    return 'table-warning';
                }
                return 'table-danger';
            },

            getRowClassSuccess() {
                return 'table-success';
            },

            checkPurchaseOrderUploadForm(e) {
                this.errors = [];

                if (!this.fileUpload) {
                    this.errors.push('Excel file is required');
                }
                if (!this.purchaseOrderAdd.fileStoreObjectName) {
                    this.errors.push('PO Contract Image is required');
                }
                if (!this.purchaseOrderAdd.fileStoreObjectName.includes('https://')) {
                    this.errors.push('PO Contract Image must be a Google drive link');
                }
                if (!this.purchaseOrderAdd.legacyNumber) {
                    this.errors.push('PO Legacy Number is required');
                }
                if (!this.purchaseOrderAdd.contractDeviceCount) {
                    this.errors.push('PO contract device limit number is required');
                }
                if (!this.errors.length) {
                    this.submitUploadPurchaseOrder();
                }

                e.preventDefault();
            },

            checkPurchaseOrderForm(e) {
                this.errors = [];

                if (!this.isRenewalPatient) {
                    if (!this.purchaseOrderAdd.patientFirstName) {
                        this.errors.push('Patient first name is required');
                    }
                    if (!this.purchaseOrderAdd.patientLastName) {
                        this.errors.push('Patient last name is required');
                    }
                    if (!this.purchaseOrderAdd.patientICN) {
                        this.errors.push('Patient ICN is required');
                    }
                    if (!this.purchaseOrderAdd.last4) {
                        this.errors.push('Patient last four of SSN is required');
                    }
                    if (!this.newPatientAddress.addressLine1) {
                        this.errors.push('Address Line 1 is required');
                    }
                    if (!this.newPatientAddress.city) {
                        this.errors.push('City is required');
                    }
                    if (!this.newPatientAddress.stateId) {
                        this.errors.push('State is required');
                    }
                    if (!this.newPatientAddress.zipCode) {
                        this.errors.push('Zip Code is required');
                    }
                }

                if (this.customerId == 1) {
                    if (!this.purchaseOrderAdd.serialNumber) {
                        this.errors.push('Device serial number is required');
                    }
                    if (!this.purchaseOrderAdd.assigned) {
                        this.errors.push('Assigned date is required');
                    }
                    if (!this.purchaseOrderAdd.shipped) {
                        this.errors.push('Shipping date is required');
                    }
                    if (!this.purchaseOrderAdd.expectedDelivery) {
                        this.errors.push('Expected delivery date is required');
                    }
                    if (!this.purchaseOrderAdd.providerId) {
                        this.errors.push('PO provider is required');
                    }
                }

                if (!this.purchaseOrderAdd.fileStoreObjectName) {
                    this.errors.push('PO Contract Image is required');
                }
                if (!this.purchaseOrderAdd.fileStoreObjectName.includes('https://')) {
                    this.errors.push('PO Contract Image must be a Google drive link');
                }
                if (!this.purchaseOrderAdd.enrollmentDate) {
                    this.errors.push('Enrollment date is required');
                }
                if (!this.purchaseOrderAdd.legacyNumber) {
                    this.errors.push('PO Legacy Number is required');
                }
                if (!this.purchaseOrderAdd.contractDeviceCount) {
                    this.errors.push('PO contract device limit number is required');
                }
                if (!this.purchaseOrderAdd.siteId) {
                    this.errors.push('Site is required');
                }
                if (this.purchaseOrderAdd.isDLC == null) {
                    this.errors.push('Is DLC is required');
                }
                if (this.purchaseOrderAdd.onFulfillmentHold == null) {
                    this.errors.push('On fulfillment hold is required');
                }
                if (this.purchaseOrderAdd.initialDeliveryMethod == null) {
                    this.errors.push('Initial delivery method is required');
                }
                if (this.purchaseOrderAdd.trainingTypeDescription == null) {
                    this.errors.push('Training type is required');
                }

                if (!this.errors.length) {
                    this.submitPurchaseOrder();
                }

                e.preventDefault();
            },

            demoCheckboxUpdated(isChecked) {
                if (isChecked) {
                    this.customerId = 5;
                    this.purchaseOrderAdd.initialDeliveryMethod = "UPS";
                    this.purchaseOrderAdd.isDLC = false;
                } else {
                    this.customerId = 1;
                    this.purchaseOrderAdd.initialDeliveryMethod = "DLC";
                    this.purchaseOrderAdd.isDLC = true;
                }
            },

            handleStateSelection(selectedItem) {
                this.newPatientAddress.state = selectedItem.name;
                this.newPatientAddress.stateId = selectedItem.stateId;
            },

            searchCleared() {
                this.fetchEntriesDebounced();
            },

            forceRerender() {
                this.componentKey += 1;
            },

            formDateFormat(date) {
                if (date != null) {
                    return moment(new Date(date)).format('l');
                }
            },
            formDateTimeFormat(date) {
                if (date != null) {
                    return moment.utc(date, 'YYYY-MM-DDTHH:mm:ss.SSSSSSS').local().format('M/D/YY h:mm A');
                }
            },

            editDateFormat(date) {
                if (date != null) {
                    return moment(new Date(date)).format('YYYY-MM-DD');
                }
            },

            initAutocomplete() {
                // Create the autocomplete object, restricting the search predictions to
                // geographical location types.
                if (this.$refs.autocomplete === undefined || this.$refs.autocomplete[0] === undefined) {
                    setTimeout(() => {
                        this.initAutocomplete();
                    }, 2000);
                } else {
                    this.autocomplete = new window.google.maps.places.Autocomplete(this.$refs.autocomplete[0].$refs.input);
                    this.autocomplete.setFields(["address_component"]);
                    this.autocomplete.setComponentRestrictions({ "country": ["us"] });
                    this.autocomplete.addListener("place_changed", () => {
                        let place = this.autocomplete.getPlace();
                        this.fillInAddress(place);
                    });
                }
            },

            validateUploadFile(file) {
                if (!file)
                    return 'file is required to upload';
                else if (file.size > 10485760)
                    return 'file size should be less than 10MB';
                else if (!this.validUploadFileType(file))
                    return 'only Excel docs (.xlsx) are allowed';
                else
                    return true;
            },

            validUploadFileType(file) {
                return this.uploadFileType.includes(file.type);
            },

            fetchEntriesDebounced() {
                // cancel pending call
                clearTimeout(this._timerId)

                // delay new call 500ms
                this._timerId = setTimeout(() => {
                    this.getPOs()
                }, 500)
            },

            async doGeocode(address) {
                // Get geocoder instance
                let geocoder = new window.google.maps.Geocoder();
                let result = null;

                // Geocode the address
                geocoder.geocode({
                    'address': address
                }, function (results, status) {
                    if (status === window.google.maps.GeocoderStatus.OK && results.length > 0) {
                        // set it to the correct, formatted address if it's valid
                        result = results[0].formatted_address;

                    } else {
                        // show an error if it's not
                        console.log("Bad Address: " + status);
                    }
                });

                return result;
            },

            fillInAddress(place) {
                // Get the place details from the autocomplete object.
                if (place != undefined) {
                    let components = {
                        street_number: {
                            result: "",
                            type: "short_name"
                        },
                        route: {
                            result: "",
                            type: "long_name"
                        },
                        locality: {
                            result: "",
                            type: "long_name"
                        },
                        administrative_area_level_1: {
                            result: "",
                            type: "short_name"
                        },
                        postal_code: {
                            result: "",
                            type: "short_name"
                        }
                    };

                    for (let i = 0; i < place.address_components.length; i++) {
                        let addressType = place.address_components[i].types[0];
                        if (typeof components[addressType] !== "undefined") {
                            components[addressType].result = place.address_components[i][components[addressType].type];
                        }
                    }

                    this.newPatientAddress.addressLine1 =
                        (components["street_number"].result + " " + components["route"].result).trim();
                    this.newPatientAddress.city = components["locality"].result;
                    this.newPatientAddress.stateId =
                        this.stateIdFromShortName(components["administrative_area_level_1"].result);
                    this.selectedState = this.stateFromShortName(components["administrative_area_level_1"].result);
                    this.newPatientAddress.state = components["administrative_area_level_1"].result;
                    this.newPatientAddress.zipCode = components["postal_code"].result;
                    this.newPatientAddress.country = "United States";
                    this.newPatientAddress.addressLine2 = "";
                }
            },

            getPOs() {
                this.loading = true;
                let pagedOptions = {
                    Search: this.search,
                    SortBy: this.options.sortBy != null ? this.options.sortBy[0] : null,
                    SortDesc: this.options.sortDesc != null ? this.options.sortDesc[0] : null,
                    ItemsPerPage: this.options.itemsPerPage,
                    Page: this.options.page
                };

                new PurchaseOrderService().getAllPurchaseOrders(pagedOptions)
                    .then((value) => {
                        this.purchaseOrders = value.items;
                        this.totalPurchaseOrders = value.totalCount;
                        this.loading = false;
                    });
            },

            clearAddPurchaseOrderForm() {
                this.purchaseOrderAdd = {
                    isDLC: true,
                    onFulfillmentHold: false,
                    initialDeliveryMethod: "DLC",
                    trainingTypeDescription: "Telephonic",
                    serialNumber: ""
                };
                this.provider = {};
                this.demoCheckbox = false;
                this.autocomplete = {};
                this.selectedState = null;
                this.$refs.autocomplete = [];
                this.newPatientAddress = {
                    addressLine1: "",
                    addressLine2: "",
                    city: "",
                    state: "",
                    stateId: null,
                    zipCode: "",
                    country: "",
                    entityTypeId: 1,
                };
                this.newPatientAddresses = [
                    {
                        entityAddressId: null,
                        addressLine1: "",
                        addressLine2: "",
                        city: "",
                        state: "",
                        stateId: null,
                        zipCode: "",
                        country: "",
                    }
                ];
                this.showRenewPurchaseOrder = false;
                this.showNoPatientRecord = false;
                this.initAutocomplete();
            },

            editItem(item) {
                this.editedIndex = this.purchaseOrders.indexOf(item);
                this.editedItem = Object.assign({}, item);
                this.dialog = true;
            },

            viewPurchaseOrderDetails(item) {
                this.editedIndex = this.purchaseOrders.indexOf(item);
                this.editedItem = Object.assign({}, item);
                this.dialogPO = true;
            },

            close() {
                this.dialog = false;
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                });
            },

            closePatientDialog() {
                this.dialogPatient = false;
                this.patientDialogValues = {};
                this.getPOs();
            },

            showDialogImportPurchaseOrders() {
                this.errors = [];
                this.dialogImportPurchaseOrders = true;
            },

            showDialogHistory() {
                this.errors = [];
                this.dialogHistory = true;
                this.getHistory();
            },

            getHistory() {
                this.loading = true;

                new OrderIntakeService().getPurchaseOrderUploadResult(this.customerId)
                    .then((value) => {
                        this.purchaseOrderUploadsHistory = value;
                        this.loading = false;
                    })
                    .catch((err) => {
                        this.submitSnackbarText = "Something went wrong: " + err;
                        this.submitSnackbar = true;
                        console.log(err);
                    });
            },
            showDialogHistoryResult(item) {
                this.errors = [];
                this.dialogResultsPurchaseOrders = true;
                this.consumeRejectedUploads(item.uploadResult);
            },

            showProviderDialog() {
                this.errors = [];
                this.dialogProvider = true;
            },

            showSiteDialog() {
                this.dialogSite = true;
            },

            showDialogAddPurchaseOrder() {
                this.errors = [];
                this.dialogAddPurchaseOrder = true;
                this.showEditPurchaseOrder = false;
            },

            closeImportPurchaseOrdersDialog() {
                this.dialogImportPurchaseOrders = false;
                this.fileUpload = null;
                this.purchaseOrderAdd = {
                    isDLC: true,
                    onFulfillmentHold: false,
                    initialDeliveryMethod: "UPS",
                    trainingTypeDescription: "Telephonic",
                };
                this.loading = false;
                this.fileUploading = false;
            },

            closeAddPurchaseOrdersDialog() {
                this.clearAddPurchaseOrderForm()
                this.dialogAddPurchaseOrder = false;
                this.showEditPurchaseOrder = false;
                this.provider = {};
            },

            closeEditPurchaseOrdersDialog() {
                this.dialogEditPurchaseOrderView = false;
                this.selectedPurchaseOrderValues = {};
                this.editPurchaseOrderValues = {};
                this.patientDialogValues = {};
                this.provider = {};
            },

            closeProviderDialog() {
                this.dialogProvider = false;
            },

            closeSiteDialog() {
                this.dialogSite = false;
            },

            closeResultsPurchaseOrdersDialog() {
                // let text = "Are you sure you want to exit Purchase Order Upload results page?"
                // if (confirm(text) == true) {
                this.dialogResultsPurchaseOrders = false;
                this.contractDeviceQuotaMet = false;
                this.rejectedUploadedOrders = [];
                this.successfulUploadedOrders = [];
                this.uploadErrorItems = [];
                this.rejectedUploadsCount = 0;
                this.successfulUploadsCount = 0;
                this.uploadErrorsCount = 0;
                this.errorRowNumber = null;
                this.errorMessage = null;
                this.errorOnUpload = false;
                // } else {
                //   this.dialogResultsPurchaseOrders = true;
                //   return false;
                // }
            },

            closeHistoryDialog() {
                this.dialogHistory = false;
            },

            closeVoidOrderDialog() {
                this.dialogVoidOrderView = false;
                this.selectedVoidOrderValues = {};
                this.selectedVoidOrderPatientValues = {};
            },

            actionSelected(item, action) {
                if (action == 'Edit') {
                    this.viewEditPurchaseOrder(item);
                } else if (action == 'Delete') {
                    this.deletePurchaseOrder(item);
                } else if (action == 'Void') {
                    this.voidOrder(item);
                } else if (action == 'UnVoid') {
                    this.unVoidOrder(item);
                }
            },

            setProviderTypeValues(event) {
                this.provider.providerTypeName = event.name;
                this.provider.providerTypeId = event.providerTypeId;
            },

            providerSelected(providerValue) {
                this.loading = true;
                if (providerValue.providerId != null) {
                    this.provider.name = providerValue.name;
                    this.purchaseOrderAdd.providerId = providerValue.providerId;
                    this.closeProviderDialog();
                }

                this.loading = false;
            },

            async deletePurchaseOrder(item) {
                let text = "Are you sure you want to delete " + item.legacyId + "?"
                if (confirm(text) == true) {
                    this.loading = true;
                    item.updatedByUserId = this.$store.state.achillesUser.userId;
                    await new CustomerPurchaseOrdersService()
                        .deleteCustomerPurchaseOrder(item)
                        .then(() => {
                            this.submitSnackbarText = "Deleted Successfully";
                            this.submitSnackbar = true;
                        })
                        .catch((err) => {
                            this.submitSnackbarText = "Something went wrong.";
                            this.submitSnackbar = true;
                            console.log(err);
                        });
                    this.getPOs();
                    this.loading = false;
                }
            },

            async saveVoid(value) {
                this.loading = true;
                this.selectedVoidOrderValues = Object.assign(this.selectedVoidOrderValues, value);
                this.selectedVoidOrderValues.updatedByUserId = this.$store.state.achillesUser.userId;
                await new CustomerPurchaseOrdersService()
                    .voidCustomerPurchaseOrder(this.selectedVoidOrderValues)
                    .then(() => {
                        this.submitSnackbarText = "Voided Successfully";
                        this.submitSnackbar = true;
                    })
                    .catch((err) => {
                        this.submitSnackbarText = "Something went wrong.";
                        this.submitSnackbar = true;
                        console.log(err);
                    });
                this.closeVoidOrderDialog();
                this.loading = false;
                this.getPOs();
            },

            voidOrder(item) {
                this.selectedVoidOrderPatientValues.patientFirstName = item.patientFirstName;
                this.selectedVoidOrderPatientValues.patientLastName = item.patientLastName;
                this.selectedVoidOrderValues.customerId = item.customerId;
                this.selectedVoidOrderValues.orderId = item.poId;
                this.selectedVoidOrderValues.patientId = item.patientId;
                this.dialogVoidOrderView = true;
            },

            async unVoidOrder(item) {
                let text = "Are you sure you want to void " + item.legacyId + "?"
                if (confirm(text) == true) {
                    this.loading = true;
                    item.updatedByUserId = this.$store.state.achillesUser.userId;
                    item.orderId = item.poId;
                    await new CustomerPurchaseOrdersService()
                        .unVoidCustomerPurchaseOrder(item)
                        .then(() => {
                            this.submitSnackbarText = "UnVoided Successfully";
                            this.submitSnackbar = true;
                        })
                        .catch((err) => {
                            this.submitSnackbarText = "Something went wrong.";
                            this.submitSnackbar = true;
                            console.log(err);
                        });
                    this.getPOs();
                    this.loading = false;
                }
            },

            async saveEdit() {
                this.loading = true;
                this.editPurchaseOrderValues.updatedByUserId = this.$store.state.achillesUser.userId;
                await new CustomerPurchaseOrdersService()
                    .editCustomerPurchaseOrder(this.editPurchaseOrderValues)
                    .then(() => {
                        this.submitSnackbarText = "Edited Successfully";
                        this.submitSnackbar = true;
                    })
                    .catch((err) => {
                        this.submitSnackbarText = "Something went wrong. Please check your inputs and try again.";
                        this.submitSnackbar = true;
                        console.log(err);
                    });
                this.closeEditPurchaseOrdersDialog();
                this.loading = false;
                this.getPOs();
            },

            async saveProvider() {
                this.loading = true;
                this.provider.updatedByUserId = this.$store.state.achillesUser.userId;
                this.provider.isDeleted = false;
                this.provider.customerId = this.customerId;
                this.provider.created = moment();
                this.provider.name = this.provider.firstName + " " + this.provider.lastName;
                await new ProviderService()
                    .postProvider(this.provider.customerId, this.provider)
                    .then((value) => {
                        this.provider.providerId = value.data.providerId;
                        this.provider.name = value.data.name;
                        this.purchaseOrderAdd.providerId = value.data.providerId;
                        this.submitSnackbarText = "Added Successfully";
                        this.submitSnackbar = true;
                    })
                    .catch((err) => {
                        this.submitSnackbarText = "Something went wrong. Please check your inputs and try again.";
                        this.submitSnackbar = true;
                        console.log(err);
                    });
                this.closeProviderDialog();
                this.loading = false;
            },

            async submitPurchaseOrder() {
                this.loading = true;
                this.purchaseOrderAdd = Object.assign(this.purchaseOrderAdd, this.newPatientAddress);

                if (this.customerId == 1 && await this.checkDeviceSerialNumberInUse()) {
                    this.submitSnackbarText = "A device with this serial number is already in use.";
                    this.submitSnackbar = true;
                    return false;
                }

                if (await this.checkPurchaseOrderDeviceQuota()) {
                    this.submitSnackbarText = "The PO Number you entered has met it's device number limit. Please use another PO Number.";
                    this.submitSnackbar = true;
                    return false;
                }

                if (await this.savePurchaseOrder()) {
                    this.submitSnackbarText = "Added Successfully";
                    this.submitSnackbar = true;
                } else {
                    this.submitSnackbarText = "Something went wrong. Please check your inputs and try again.";
                    this.submitSnackbar = true;
                    console.log("PO Submit Bad: ");
                }

                this.loading = false;
                this.closeAddPurchaseOrdersDialog();
                this.getPOs();
            },

            async submitUploadPurchaseOrder() {
                this.loading = true;
                this.fileUploading = true;
                try {
                    let savePurchaseOrderResult = await this.uploadPurchaseOrder();
                    if (savePurchaseOrderResult) {
                        this.submitSnackbarText = "Added Successfully";
                        this.submitSnackbar = true;
                    }

                } catch (err) {
                    this.submitSnackbarText = "Something went wrong. Please check your inputs and try again.";
                    this.submitSnackbar = true;
                    console.log("PO Result Bad: " + err);
                }

                this.closeImportPurchaseOrdersDialog();
                this.loading = false;
                this.fileUploading = false;
                this.getPOs();
            },

            async uploadPurchaseOrder() {
                try {
                    this.purchaseOrderAdd.customerId = this.customerId;
                    this.purchaseOrderAdd.updatedByUserId = this.$store.state.achillesUser.userId;
                    await new OrderIntakeService().submitUploadedPurchaseOrder(this.fileUpload, this.purchaseOrderAdd);
                    this.showDialogHistory();
                } catch (err) {
                    console.log("PO Result Bad: " + err);
                    this.submitSnackbarText = "Something went wrong. Please check your inputs and try again.";
                    this.submitSnackbar = true;
                }
            },

            async savePurchaseOrder() {
                try {
                    this.purchaseOrderAdd.customerId = this.customerId;
                    this.purchaseOrderAdd.isRenewalPatient = this.isRenewalPatient || false;
                    this.purchaseOrderAdd.updatedByUserId = this.$store.state.achillesUser.userId;
                    await new OrderIntakeService().submitPurchaseOrder(this.purchaseOrderAdd);
                    this.getPOs();
                    return true;
                } catch (err) {
                    console.log("PO Result Bad: " + err);
                    return false;
                }
            },

            checkDeviceSerialNumberInUse() {
                return new Promise((resolve) => {
                    (async () => {
                        try {
                            await new PatientDeviceService()
                                .getPatientDevicBySerialNumber(this.purchaseOrderAdd.serialNumber)
                                .then((value) => {
                                    const deviceInUse = value.devices.length !== 0 || value.devices[0] !== undefined;
                                    return resolve(deviceInUse);
                                });
                        } catch (err) {
                            this.submitSnackbarText = "Something went wrong. Please check your inputs and try again.";
                            this.submitSnackbar = true;
                            console.log("Error checking for device serial number: " + err);
                        }
                    })();
                })
            },

            checkPurchaseOrderDeviceQuota() {
                return new Promise((resolve) => {
                    (async () => {
                        try {
                            this.purchaseOrderAdd.customerId = this.customerId;
                            await new CustomerPurchaseOrdersService()
                                .getPurchaseOrdersByOrderNumber(this.purchaseOrderAdd.customerId, this.purchaseOrderAdd.legacyNumber)
                                .then((value) => {
                                    if (value.purchaseOrders.length !== 0 || value.purchaseOrders[0] !== undefined) {
                                        return resolve(value.purchaseOrders.length >= this.purchaseOrderAdd.contractDeviceCount);
                                    } else {
                                        return resolve(false);
                                    }
                                });
                        } catch (err) {
                            this.submitSnackbarText = "Something went wrong. Please check your inputs and try again.";
                            this.submitSnackbar = true;
                            console.log("Error checking for device serial number: " + err);
                        }
                    })();
                })
            },

            viewEditPurchaseOrder: async function (item) {
                this.loading = true;
                await new CustomerPurchaseOrdersService()
                    .getPurchaseOrderDetails(item.customerId, item.poId)
                    .then((value) => {
                        this.editPurchaseOrderValues = Object.assign({}, value);
                        this.patientDialogValues.patientFirstName = item.patientFirstName;
                        this.patientDialogValues.patientLastName = item.patientLastName;
                        this.editPurchaseOrderValues.customerId = item.customerId;
                        this.editPurchaseOrderValues.poId = item.poId;
                        this.editPurchaseOrderValues.poDate = this.editDateFormat(value.poDate);
                        this.editPurchaseOrderValues.processed = this.editDateFormat(value.processed);
                        this.editPurchaseOrderValues.received = this.editDateFormat(value.received);
                        this.editPurchaseOrderValues.assigned = this.editDateFormat(value.assigned);
                        this.editPurchaseOrderValues.delivered = this.editDateFormat(value.delivered);
                        this.editPurchaseOrderValues.trained = this.editDateFormat(value.trained);
                        this.patientSiteId = value.siteId;
                        this.dialogEditPurchaseOrderView = true;
                    })
                    .finally(() => (this.loading = false));

                if (this.editPurchaseOrderValues.providerId != null) {
                    await this.getProviderName(item.customerId, this.editPurchaseOrderValues.providerId);

                }
            },

            viewPurchaseOrder: async function (item) {
                this.loading = true;
                this.selectedPurchaseOrderValues = {};
                await new CustomerPurchaseOrdersService()
                    .getPurchaseOrderDetails(item.customerId, item.poId)
                    .then((value) => {
                        this.selectedPurchaseOrderValues = Object.assign({}, value);
                        this.selectedPurchaseOrderValues.patientFirstName = item.patientFirstName;
                        this.selectedPurchaseOrderValues.patientLastName = item.patientLastName;
                        this.selectedPurchaseOrderValues.customerId = item.customerId;
                        this.selectedPurchaseOrderValues.poId = item.purchaseOrderId;
                        this.selectedPurchaseOrderValues.legacyId = item.legacyId;
                        this.selectedPurchaseOrderValues.updatedByUserName = item.updatedByUserName;
                        this.selectedPurchaseOrderValues.isRenewalPatient = item.isRenewalPatient;
                        this.selectedPurchaseOrderValues.voidDate = item.voidDate;
                        this.selectedPurchaseOrderValues.voidedReasonId = item.voidedReasonId;
                        this.selectedPurchaseOrderValues.voidNote = item.voidNote;
                        this.dialogPO = true;
                    })
                    .finally(() => (this.loading = false));
            },

            viewPatient(item) {
                this.patientDialogValues.patientId = item.patientId;
                this.patientDialogValues.customerId = item.customerId;
                this.patientDialogValues.firstName = item.patientFirstName;
                this.patientDialogValues.lastName = item.patientLastName;
                this.patientDialogValues.customerName = item.customerName;
                this.patientDialogValues.patientDesignation = item.patientDesignation;
                this.dialogPatient = true;
            },

            searchPurchaseOrder: async function (patientValue) {
                this.loading = true;
                await new CustomerPurchaseOrdersService()
                    .getPurchaseOrderByPatientId(patientValue.customerId, patientValue.patientId)
                    .then((value) => {
                        if (value.purchaseOrderId != null) {
                            this.showNoPatientRecord = false;
                            this.getSelectedPatientPurchaseOrder(patientValue.customerId, value.purchaseOrderId, patientValue);
                        } else {
                            this.showNoPatientRecord = true;
                        }
                    })
                    .finally(() => (this.loading = false));
            },

            getProviderName: async function (customerId, providerId) {
                this.loading = true;
                await new ProviderService()
                    .get(customerId, providerId)
                    .then((value) => {
                        this.provider.name = value.firstName + " " + value.lastName;
                    })
                    .finally(() => (this.loading = false));
            },

            async searchSites(siteValue) {
                this.loading = true;
                if (siteValue.siteId != null) {
                    this.site = Object.assign({}, siteValue);
                    this.purchaseOrderAdd.siteId = siteValue.siteId;
                    this.purchaseOrderAdd.siteName = this.site.name;
                    this.patientSiteId = siteValue.siteId;
                    this.closeSiteDialog();
                }

                this.loading = false;
            },

            async searchProviders(providerValue) {
                this.loading = true;
                if (providerValue.providerId != null) {
                    this.provider = Object.assign({}, providerValue);
                    this.purchaseOrderAdd.providerId = providerValue.providerId;
                    this.editPurchaseOrderValues.providerId = providerValue.providerId;
                    this.closeProviderDialog();
                }

                this.loading = false;
            },

            consumeRejectedUploads(value) {
                if (value != null) {
                    this.rejectedUploadedOrders = value.uploadRejections;
                    this.rejectedUploadsCount = this.rejectedUploadedOrders != null ? this.rejectedUploadedOrders.length : 0;
                    this.successfulUploadedOrders = value.successfulUploads;
                    this.successfulUploadsCount = this.successfulUploadedOrders != null ? this.successfulUploadedOrders.length : 0;
                    this.uploadErrorItems = value.uploadErrors;
                    this.uploadErrorsCount = this.uploadErrorItems != null ? this.uploadErrorItems.length : null;
                    this.contractDeviceQuotaMet = value.contractDeviceQuotaMet;
                    this.errorOnUpload = value.errorOnUpload;
                    this.errorRowNumber = value.rowErrorOccurredOn;
                    this.errorMessage = value.errorMessage
                }
                this.dialogResultsPurchaseOrders = true;
                this.loading = false;
                console.log(value);
            },

            getSelectedPatientPurchaseOrder: async function (customerId, poId, patientValue) {
                this.loading = true;
                this.showRenewPurchaseOrder = false;
                this.showTooSoonToRenew = false;
                this.purchaseOrderAdd = {};
                await new CustomerPurchaseOrdersService()
                    .getPurchaseOrderDetails(customerId, poId)
                    .then((value) => {
                        if (value.purchaseOrderId != null) {
                            if (moment().diff(moment(value.poDate), 'months') < 11) {
                                this.showTooSoonToRenew = true;
                            } else {
                                this.showNoPatientRecord = false;
                                this.customerId = patientValue.customerId;
                                this.purchaseOrderAdd.patientId = patientValue.patientId;
                                this.purchaseOrderAdd.isRenewalPatient = this.isRenewalPatient;
                                this.purchaseOrderAdd.patientFirstName = patientValue.firstName;
                                this.purchaseOrderAdd.patientLastName = patientValue.lastName;
                                this.showRenewPurchaseOrder = true;
                            }
                        } else {
                            this.showNoPatientRecord = true;
                            this.showRenewPurchaseOrder = false;
                        }
                    })
                    .finally(() => (this.loading = false));
            },
        },
    });
</script>

<style>
    .table-danger {
        background-color: #f5c6cb;
    }

    .table-warning {
        background-color: #ffeeba;
    }

    .table-success {
        background-color: #e1ffe3;
    }

    .text-success {
        color: #16bc2c;
    }

    .text-danger {
        color: #983a37;
    }
</style>