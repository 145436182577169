import * as device from '../types/device';
import * as baseService from './BaseService';


export default class PatientDeviceService extends baseService.BaseService {
    private url = '/api/PatientDevice';

    constructor() {
        super();
    }

    async getPatientDevicBySerialNumber(deviceSerialNumber: string): Promise<device.PatientDevice> {
        let url = this.url + `/GetPatientDeviceBySerialNumber/${deviceSerialNumber}`;
        let response;
        response = await this.api.get(url).catch(err => {
            throw err;
        });
        return response.data;
    }
}