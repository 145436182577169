import * as master from '../types/master';
import * as customer from '../types/customer';
import * as baseService from './BaseService';

export default class CustomerPurchaseOrdersService extends baseService.BaseService {
    private url = '/api/CustomerPurchaseOrders';

    constructor() {
        super();
    }

    async getPurchaseOrderDetails(customerId: number, poId: number): Promise<customer.PurchaseOrder> {
        let url = this.url + `/${customerId}/${poId}`;
        let response;
        response = await this.api.get(url).catch(err => {
            throw err;
        });
        return response.data;
    }

    async getPurchaseOrderByPatientId(customerId: number, patientId: number): Promise<customer.PurchaseOrderPatient> {
        let url = this.url + `/GetPurchaseOrderByPatientId/${customerId}/${patientId}`;
        let response;
        response = await this.api.get(url).catch(err => {
            throw err;
        });
        return response.data;
    }

    async getPurchaseOrdersByOrderNumber(customerId: number, purchseOrderNumber: string): Promise<Array<customer.PurchaseOrder>> {
        let url = this.url + `/GetPurchaseOrdersByOrderNumber/${customerId}/${purchseOrderNumber}`;
        let response;
        response = await this.api.get(url).catch(err => {
            throw err;
        });
        return response.data;
    }

    async deleteCustomerPurchaseOrder(command: master.CustomerPurchaseOrder) {
        let url = this.url + '/DeletePurchaseOrder/';
        let response = await this.api.put(url, command).catch(err => {
            throw err;
        });
        return response;
    }

    async voidCustomerPurchaseOrder(command: master.CustomerVoidOrder) {
        let url = this.url + '/VoidPurchaseOrder/';
        let response = await this.api.put(url, command).catch(err => {
            throw err;
        });
        return response;
    }

    async unVoidCustomerPurchaseOrder(command: master.CustomerVoidOrder) {
        let url = this.url + '/UnVoidPurchaseOrder/';
        let response = await this.api.put(url, command).catch(err => {
            throw err;
        });
        return response;
    }

    async editCustomerPurchaseOrder(command: customer.PurchaseOrder) {
        let url = this.url + '/Update';
        let response = await this.api.put(url, command).catch(err => {
            throw err;
        });
        return response;
    }
} 